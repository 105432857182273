import { createSlice } from "@reduxjs/toolkit";

interface ISocketSliceState {
  incomingMessages: string[];
  outgoingMessages: string[];
  isConnected: boolean;
}

const initialState: ISocketSliceState = {
  incomingMessages: [],
  outgoingMessages: [],
  isConnected: false,
};

export const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: { // these reducers are intercepted by the websocket middleware
    connect: (state, action) => {},
    disconnect: (state) => {},
    sendMessage: (state, action) => {
      state.outgoingMessages = [...state.outgoingMessages, action.payload].slice(-200);
      return state;
    },
    addIncomingMessage: (state, action) => {
      state.incomingMessages = [...state.incomingMessages, action.payload].slice(-200);
      return state;
    },
    setIsConnected: (state, action) => {
      state.isConnected = action.payload;
      return state;
    }
  },
});

export const socketActions = socketSlice.actions;
export const socketSliceReducers = socketSlice.reducer;
