import "./ConnectionIndicator.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import { socketActions } from "../../../../../../store/socketSlice";

const ConnectionIndicator = () => {
  const connectionState = useAppSelector((s) => s.socket.isConnected);
  const dispatch = useAppDispatch();

  const classes = connectionState ? `connected` : `disconnected`;
  const statusText = connectionState ? `Verbunden` : `Nicht verbunden`;

  const toggleConnection = () => {
    if (connectionState) {
      dispatch(socketActions.disconnect());
    }
  }

  return (
    <div className="connection-indicator" onClick={toggleConnection}>
      <span className={classes}>{statusText}</span>
    </div>
  );
};

export default ConnectionIndicator;
