import "./TitleBox.scss";

interface ITitleBoxProps {
  title: string;
  children?: any;
}

const TitleBox = (props: ITitleBoxProps) => {
  
  return (
    <div className="title-box">
      <div className="title">
        {props.title}
      </div>
      <div className="actions">
        {props.children}
      </div>
    </div>
  )
}

export default TitleBox