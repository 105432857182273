import "./MainStatusBar.scss";
import ConnectionIndicator from "./components/connection-indicator/ConnectionIndicator";

const MainStatusBar = () => {
  return (
    <div className="main-status-bar">
      <div className="primary">
        <ConnectionIndicator />
      </div>
    </div>
  );
};

export default MainStatusBar;
