import "./DashboardPage.scss";
import MainLayout from "../../layouts/main-layout/MainLayout";
import TitleBox from "../../ui/title-box/TitleBox";
import Controller from "./components/controller/Controller";
import { useAppSelector } from "../../../store/store";

const DashboardPage = () => {
  const players = useAppSelector((s) => s.game.players);

  return (
    <MainLayout>
      <div className="dashboard-page">
        <TitleBox title={"Kontroller-Status"} />
        <div className="c-wrapper">
          {players &&
            players.map((p, i) => {
              return <Controller id={p.playerId} key={p.playerId} />;
            })}
        </div>
      </div>
    </MainLayout>
  );
};

export default DashboardPage;
