import "./MainLayout.scss";
import MainHeader from "./main-header/MainHeader";
import MainMenu from "./main-menu/MainMenu";
import MainStatusBar from "./main-status-bar/MainStatusBar";

interface IMainLayoutProps {
  children: any;
}

const MainLayout = (props: IMainLayoutProps) => {
  return (
    <div className="main-layout">
      <MainStatusBar />
      <MainHeader />
      <MainMenu />
      <div className="main-content">
        <div className="main-content-wrapper">{props.children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
