import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import MainLayout from "../../layouts/main-layout/MainLayout";
import "./SetupPage.scss";
import { socketActions } from "../../../store/socketSlice";
import TitleBox from "../../ui/title-box/TitleBox";

const SetupPage = () => {
  const config = useAppSelector((s) => s.config);
  const dispatch = useAppDispatch();

  const [ip, setIP] = useState(config.serverAddress);
  const [port, setPort] = useState(config.serverPort);

  const saveAndConnect = () => {
    dispatch(
      socketActions.connect({
        ip,
        port,
      })
    );
  };

  return (
    <MainLayout>
      <div className="setup-page">
        <TitleBox title="Setup" />
        <div className="form-wrapper">
          <input
            type="text"
            className="input-field"
            placeholder="ip address / hostname"
            name="ipAddress"
            value={ip ?? ""}
            onChange={(e) => setIP(e.target.value)}
          />
          <input
            type="text"
            className="input-field"
            placeholder="port"
            name="port"
            value={port ?? ""}
            onChange={(e) => setPort(e.target.value)}
          />
          <button className="btn btn-primary" onClick={saveAndConnect}>Connect</button>
        </div>
      </div>
    </MainLayout>
  );
};

export default SetupPage;
