import "./DPadKey.scss";

interface IKeyProps {
  posX: number;
  posY: number;
  direction: ArrowDirection;
  pressed?: boolean;
}

export enum ArrowDirection {
  UP = "up",
  DOWN = "down",
  LEFT = "left",
  RIGHT = "right",
}

const DPadKey = (props: IKeyProps) => {

  const wrapperStyle: React.CSSProperties = {
    position: "absolute",
    left: `${props.posX}px`,
    top: `${props.posY}px`,
  };

  const classNames = `dpad-key${props.pressed ? ` key-pressed` : ``}`;

  return <div className={classNames} style={wrapperStyle}><i className={`fas fa-arrow-${props.direction}`}></i></div>;
};

export default DPadKey;
