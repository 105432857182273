import { Middleware } from "redux";
import { socketActions } from "../socketSlice";
import IMessage from "../../models/message.model";
import { gameActions } from "../gameSlice";
import IPlayer from "../../models/player.model";
import { configActions } from "../configSlice";
import ControllerMap from "../../enums/controllerMap.enum";

const socketMiddleware: Middleware = (store) => {
  let socket: WebSocket;

  return (next) => (action) => {
    if (socketActions.connect.match(action)) {
      const payload: any = action.payload;
      socket = new WebSocket(
        `ws://${payload?.ip}:${
          payload?.port
        }`
      );

      socket.onmessage = function (event) {
        store.dispatch(socketActions.addIncomingMessage(event.data));
        let message: IMessage;

        try {
          message = JSON.parse(event.data);
        } catch (e) {
          console.log(`Unable to parse message`, event.data);
          return
        }

        const playerId: number = message.p;
        const type: string = message.k;

        if (type === "leftStick") {
          const p: IPlayer = {
            playerId,
            leftStick: message.v as number[],
            rightStick: [],
            buttons: 0,
            dpad: 8
          }
          store.dispatch(gameActions.updateLeftStick(p));
        } else if (type === "rightStick") {
          const p: IPlayer = {
            playerId,
            leftStick: [],
            rightStick: message.v as number[],
            buttons: 0,
            dpad: 8
          }
          store.dispatch(gameActions.updateRightStick(p));
        } else if (type === "buttons") {
          const pressed = message.v as number;
          const swapped = ((pressed & 0x00FF) << 8) | ((pressed & 0xFF00) >> 8);
          
          const p: IPlayer = {
            playerId,
            leftStick: [],
            rightStick: [],
            buttons: swapped,
            dpad: 8
          }
          store.dispatch(gameActions.updateButtons(p));
        } else if (type === "dpad") {
          const dpadState = message.v as number;

          const p: IPlayer = {
            playerId,
            leftStick: [],
            rightStick: [],
            buttons: 0,
            dpad: dpadState
          }
          store.dispatch(gameActions.updateDpad(p));
        }
      };

      socket.onopen = function () {
        store.dispatch(socketActions.setIsConnected(true));
        store.dispatch(configActions.setServerAddress(payload.ip));
        store.dispatch(configActions.setServerPort(payload.port));
      };

      socket.onclose = function () {
        store.dispatch(socketActions.setIsConnected(false));
        store.dispatch(gameActions.reset());
      };

      socket.onerror = function (e) {
        console.log(e);
      };
    } else if (socketActions.disconnect.match(action)) {
      if (socket.OPEN) {
        socket.close();
      }
    } else if (socketActions.sendMessage.match(action)) {
      if (socket.OPEN) {
        socket.send(action.payload);
      }
    }

    next(action);
  };
};

export default socketMiddleware;
