import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { gameSliceReducers } from "./gameSlice";
import { socketSliceReducers } from "./socketSlice";
import socketMiddleware from "./middleware/socketMiddleware";
import { configSliceReducers } from "./configSlice";
import { profileSliceReducers } from "./profileSlice";

const store = configureStore({
  reducer: {
    game: gameSliceReducers,
    socket: socketSliceReducers,
    config: configSliceReducers,
    profile: profileSliceReducers,
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware().concat([
      socketMiddleware
    ]);
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;

export type AppDispatch = typeof store.dispatch; // Here we export the store's dispatch type
export type RootState = ReturnType<typeof store.getState>; // Here we export the store's state

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
