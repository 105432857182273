import { Navigate, Route, Routes } from "react-router-dom";
import DashboardPage from "../pages/dashboard-page/DashboardPage";
import { useAppSelector } from "../../store/store";
import { Fragment } from "react/jsx-runtime";
import SetupPage from "../pages/setup-page/SetupPage";

const App = () => {
  const socketConnected = useAppSelector((s) => s.socket.isConnected);

  return (
    <Routes>
      {socketConnected && (
        <Fragment>
          <Route path="/" element={<DashboardPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Fragment>
      )}
      {!socketConnected && (
        <Fragment>
          <Route path="/setup" element={<SetupPage />} />
          <Route path="*" element={<Navigate to="/setup" replace />} />
        </Fragment>
      )}
    </Routes>
  );
};

export default App;
