import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../../../store/store";
import "./Joystick.scss";

interface IJoystickProps {
  posX: number;
  posY: number;
  playerId: number;
  type: "left" | "right";
  pressed?: boolean;
}

const Joystick = (props: IJoystickProps) => {

  const pData = useAppSelector(s => s.game.players[props.playerId]);

  const [l, setL] = useState<number[]>([0, 0]);
  const [r, setR] = useState<number[]>([0, 0]);

  const indicatorClasses = `indicator${props.pressed ? ` pressed` : ``}`

  useEffect(() => {
    if (props.type === "left") {
      if (pData.leftStick && pData.leftStick.length === 2) {
        const newL = pData.leftStick.map(value => Math.round((value / 255) * 100));
        setL(newL);
      }
    } else if (props.type === "right") {
      if (pData.rightStick && pData.rightStick.length === 2) {
        const newR = pData.rightStick.map(value => Math.round((value / 255) * 100));
        setR(newR);
      }
    }
    
  }, [pData.leftStick, pData.rightStick]);

  const wrapperStyle: React.CSSProperties = {
    position: 'absolute',
    left: `${props.posX}px`,
    top: `${props.posY}px`
  };

  const indicatorStyle: React.CSSProperties = {
    left: `calc(${props.type === 'right' ? r[0] : l[0]}% - 10px)`,
    top: `calc(${props.type === 'right' ? r[1] : l[1]}% - 10px)`,
  }

  return (
    <div className="joystick" style={wrapperStyle}>
      <div className={indicatorClasses} style={indicatorStyle}></div>
    </div>
  )
}

export default Joystick;