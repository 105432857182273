import { NavLink } from "react-router-dom";
import "./MainMenu.scss";
import { useAppSelector } from "../../../../store/store";
import { Fragment } from "react/jsx-runtime";

const MainMenu = () => {
  const socketConnected = useAppSelector((s) => s.socket.isConnected);

  return (
    <div className="main-menu">
      {socketConnected && (
        <Fragment>
          <NavLink
            to="/"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            Dashboard
          </NavLink>
        </Fragment>
      )}
      {!socketConnected && (
        <NavLink
          to="/settings"
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
        >
          Setup
        </NavLink>
      )}
    </div>
  );
};

export default MainMenu;
