import "./Key.scss";

interface IKeyProps {
  posX: number;
  posY: number;
  name?: string;
  icon?: string;
  pressed?: boolean;
  specialKey?: boolean;
  color?: KeyColor
}

export enum KeyColor {
  BLUE = "blue",
  RED = "red",
  GREEN = "green",
  YELLOW = "yellow"
}

const Key = (props: IKeyProps) => {
  const wrapperStyle: React.CSSProperties = {
    position: "absolute",
    left: `${props.posX}px`,
    top: `${props.posY}px`,
  };

  const classNames = `key${props.specialKey ? ` key-special` : ``}${props.color ? ` key-colored-${props.color}` : ``}${props.pressed ? ` key-pressed` : ``}`;

  return (
    <div className={classNames} style={wrapperStyle}>
      {props.name && (
        <span>{props.name}</span>
      )}
      {props.icon && (
        <i className={props.icon ?? ""}></i>
      )}
    </div>
  );
};

export default Key;
