import { createSlice } from "@reduxjs/toolkit";

interface IConfigSliceState {
  serverAddress: string;
  serverPort: string;
}

const initialState: IConfigSliceState = {
  serverAddress: "172.22.4.120", // 172.22.4.120
  serverPort: "1322", // 1322
};

export const configSlice = createSlice({
  name: "config",
  initialState: initialState,
  reducers: {
    setServerAddress: (state, action) => {
      state.serverAddress = action.payload;
      return state;
    },
    setServerPort: (state, action) => {
      state.serverPort = action.payload;
      return state;
    },
  },
});

export const configActions = configSlice.actions;
export const configSliceReducers = configSlice.reducer;
