enum ControllerMap {
  BUTTON_A = 2,
  BUTTON_B = 1,
  BUTTON_X = 3,
  BUTTON_Y = 0,
  BUTTON_L = 4,
  BUTTON_R = 5,
  BUTTON_ZL = 6,
  BUTTON_ZR = 7,
  BUTTON_LS = 10,
  BUTTON_RS = 11,
  BUTTON_MINUS = 8,
  BUTTON_PLUS = 9,
  BUTTON_CAPTURE = 13,
  BUTTON_HOME = 12
}

export default ControllerMap;
