import { createSlice } from "@reduxjs/toolkit";
import IPlayer from "../models/player.model";

interface IGameSliceState {
  players: IPlayer[];
}

const initPlayer = (id: number): IPlayer => {
  const p: IPlayer = {
    playerId: id,
    leftStick: [128, 128],
    rightStick: [128, 128],
    buttons: 0,
    dpad: 8
  };

  return p
}

const initialState: IGameSliceState = {
  players: [
    initPlayer(0),
    initPlayer(1),
    initPlayer(2),
    initPlayer(3)
  ]
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    updateLeftStick: (state, action) => {
      const data: IPlayer = action.payload;

      state.players[data.playerId].leftStick = data.leftStick;

      return state;
    },
    updateRightStick: (state, action) => {
      const data: IPlayer = action.payload;

      state.players[data.playerId].rightStick = data.rightStick;

      return state;
    },
    updateButtons: (state, action) => {
      const data: IPlayer = action.payload;
      
      state.players[data.playerId].buttons = data.buttons;

      return state;
    },
    updateDpad: (state, action) => {
      const data: IPlayer = action.payload;
      
      state.players[data.playerId].dpad = data.dpad;

      return state;
    },
    reset: (state) => {
      state = initialState;
      return state;
    }
  },
});

export const gameActions = gameSlice.actions;
export const gameSliceReducers = gameSlice.reducer;
