import { useAppSelector } from "../../../../../store/store";
import Joystick from "./components/joystick/Joystick";
import Key, { KeyColor } from "./components/key/Key";
import "./Controller.scss";
import ControllerMap from "../../../../../enums/controllerMap.enum";
import DPadKey, { ArrowDirection } from "./components/dpad-key/DPadKey";

interface IControllerProps {
  id: number;
}

const Controller = (props: IControllerProps) => {

  const player = useAppSelector(s => s.game.players[props.id]);

  const isKeyPressed = (key: ControllerMap) => (player.buttons & (1 << key)) !== 0;

  return (
    <div className="controller">
      <h1>Player {props.id}</h1>
      <div className="controller-wrapper">

        <Joystick posX={72} posY={62} type="left" playerId={props.id} pressed={isKeyPressed(ControllerMap.BUTTON_LS)} />
        <Joystick posX={229} posY={124} type="right" playerId={props.id} pressed={isKeyPressed(ControllerMap.BUTTON_RS)} />

        <Key posX={262} posY={73} name="X" pressed={isKeyPressed(ControllerMap.BUTTON_X)} color={KeyColor.BLUE} />
        <Key posX={290} posY={45} name="Y" pressed={isKeyPressed(ControllerMap.BUTTON_Y)} color={KeyColor.YELLOW} />
        <Key posX={290} posY={101} name="A" pressed={isKeyPressed(ControllerMap.BUTTON_A)} color={KeyColor.GREEN} />
        <Key posX={320} posY={73} name="B" pressed={isKeyPressed(ControllerMap.BUTTON_B)} color={KeyColor.RED} />
        <Key posX={160} posY={76} icon="far fa-clone" pressed={isKeyPressed(ControllerMap.BUTTON_MINUS)} specialKey />
        <Key posX={220} posY={76} icon="fas fa-bars" pressed={isKeyPressed(ControllerMap.BUTTON_PLUS)} specialKey />
        <Key posX={190} posY={106} icon="fas fa-arrow-up-from-bracket" pressed={isKeyPressed(ControllerMap.BUTTON_CAPTURE)} specialKey />
        
        <Key posX={280} posY={-20} name="R1" pressed={isKeyPressed(ControllerMap.BUTTON_R)} />
        <Key posX={315} posY={-8} name="R2" pressed={isKeyPressed(ControllerMap.BUTTON_ZR)} />
        <Key posX={55} posY={-8} name="L2" pressed={isKeyPressed(ControllerMap.BUTTON_ZL)} />
        <Key posX={90} posY={-20} name="L1" pressed={isKeyPressed(ControllerMap.BUTTON_L)} />

        <DPadKey posX={136} posY={123} direction={ArrowDirection.UP} pressed={[0,1,7].indexOf(player.dpad) !== -1 } />
        <DPadKey posX={136} posY={161.5} direction={ArrowDirection.DOWN} pressed={[3,4,5].indexOf(player.dpad) !== -1 } />
        <DPadKey posX={156} posY={142.5} direction={ArrowDirection.RIGHT} pressed={[1,2,3].indexOf(player.dpad) !== -1 } />
        <DPadKey posX={116} posY={142.5} direction={ArrowDirection.LEFT} pressed={[5,6,7].indexOf(player.dpad) !== -1 } />
      </div>
    </div>
  );
};

export default Controller;
