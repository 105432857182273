import "./MainHeader.scss";
import logo from "../../../../images/logo.png"

const MainHeader = () => {

    return (
        <div className="main-header">
            <img src={logo} alt="Logo" />
        </div>
    )
}

export default MainHeader;