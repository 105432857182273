import { createSlice } from "@reduxjs/toolkit";
import { IProfile } from "../models/profile.model";

interface IProfileSliceState {
  profiles: IProfile[];
}

const initialState: IProfileSliceState = {
  profiles: [],
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    addProfile: (state, action) => {
      state.profiles = [...state.profiles, action.payload];
      return state;
    },
    removeProfileByName: (state, action) => {
      state.profiles = state.profiles.filter((p) => p.name !== action.payload);
      return state;
    },
  },
});

export const profileActions = profileSlice.actions;
export const profileSliceReducers = profileSlice.reducer;
